<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px">
      <div class="query-c">
        <Row>
          <Col span="5">
                <Input v-model="carNo" placeholder='车牌号码'  style="width: 150px" />
          </Col>
            <Col span="5">
                <Input v-model="bdName" placeholder='保单姓名'  style="width: 150px" />
          </Col>
            <Col span="5">
               <Select v-model="uid" filterable clearable placeholder="客服"  style="width:150px">
                        <Option v-for="item in userList" :value="item.uid" :label="item.userName" :key="item.uid">
                          <span>{{item.userName}}</span>
                          <span style="float:right;color:#ccc"> {{item.userNo}}</span>
                        </Option>
                </Select>
          </Col>
           <Col span="5"  >
                <DatePicker
                :value="dataList"
                @on-change="dateHandleChange"
                format="yyyy/MM/dd"
                type="datetimerange"
                placement="bottom-end"
                placeholder="选择日期"
                style="width: 150px"></DatePicker>
            </Col>
          <Col span="4">
                <Button type="primary"  class="add" @click="findBusinessVoList()"  icon="md-search">查询</Button>
          </Col>
        </Row>
    </div>
      <Table
        :max-height="maxHeight"
        border
        stripe
        :columns="columns"
        :data="businessVoList"
      ></Table>
      <br />
      <Page
            :total="total"
            @on-change="changePage"
            @on-page-size-change="changePageNum"
            show-sizer
            show-elevator />
    </div>
    <Modal
        v-model="vModel"
        title="删除"
        @on-ok="vOk"
        @on-cancel="vCancel">
        <p>修改提交时间为： {{confirmTime}}</p>
    </Modal>


  </div>
</template>

<script>
import { findBusinessVoList, updateCreateTime, findUserList } from '@/api'

let moment = require('moment')
 // require
export default {
  name: 'verify',
  data() {
    return {
      maxHeight: 800,
      pageIndex: 1,
      pageSize: 10,
      pageNum: 10,
      total: 100,
      business: '',
      businessVoList: [],
      dataList: [],
      startTime: 0,
      endTime: 0,
      vModel: false,
      confirmTime: '',
      modifyTime: 0,
      upBid: 0,
      userNo: '',
      bdName: '',
      carNo: '',
      uid: '',
      userList: [],
      columns: [
           {
              type: 'index',
              width: 60,
              align: 'center',
            },
        {
              title: '门店',
              key: 'shopName',
               width: 150,
              resizable: true,
        },
        {
                        title: '公司',
                        key: 'companyName',
                        width: 150,
                        resizable: true,
        },
        {
                        title: '车牌号',
                        key: 'carNo',
                        resizable: true,
                         width: 150,
        },
        {
                        title: '保单姓名',
                        key: 'bdName',
                        width: 150,
                        resizable: true,
                    },
                    {
                        title: '商业险',
                        key: 'sy',
                        sortable: true,
                        width: 150,
                        resizable: true,
                    },
                    {
                        title: '交强险',
                        key: 'jq',
                        sortable: true,
                        width: 100,
                        resizable: true,
                    },
                    {
                        title: '车船税',
                        key: 'ccs',
                        sortable: true,
                        width: 100,
                        resizable: true,
                    },
                    {
                        title: '驾意险',
                        key: 'jy',
                        sortable: true,
                        width: 100,
                        resizable: true,
                    },
                    {
                        title: '商业险机构',
                        key: 'consumerName',
                        width: 180,
                    },
                     {
                        title: '交强/车船机构',
                        key: 'jqOrg',
                        width: 180,
                    },
                    {
                        title: '驾意险机构',
                        key: 'jyOrg',
                        width: 180,
                    },
                    {
                        title: '返点金额',
                        key: 'rebateAmount',
                        sortable: true,
                        width: 150,
                        resizable: true,
                    },
                    {
                        title: '返点详情',
                        key: 'rebate',
                        width: 150,
                        resizable: true,
                    },
                    {
                        title: '驾意返点详情',
                        key: 'jyRebate',
                        width: 150,
                        resizable: true,
                    },
                    {
                        title: '盈亏',
                        key: 'yk',
                        width: 100,
                        resizable: true,
                    },
                    {
                        title: '驾意盈亏',
                        key: 'jyYk',
                        width: 100,
                        resizable: true,
                    },
                    {
                        title: '驾意返点',
                        key: 'jyRebateAmount',
                        width: 100,
                        resizable: true,
                    },
                    {
                        title: '联系方式',
                        key: 'phone',
                        width: 150,
                        resizable: true,
                    },
                    {
                        title: '日期',
                        key: 'modifyTime',
                        resizable: true,
                        width: 150,
                        render: (h, params) => h('div',
                        moment(params.row.modifyTime).format('YYYY-MM-DD HH:mm:ss')),
                    },
                    {
                        title: '来源',
                        key: 'source',
                        width: 150,
                    },
                    {
                        title: '备注',
                        key: 'remark',
                        resizable: true,
                        width: 150,
                    },
        {
          title: '操作',
          key: 'action',
          width: 150,
          fixed: 'right',
          align: 'center',
          render: (h, params) => h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.update(params)
                    },
                  },
                },
                '审核'
              ),
            ]),
        },
      ],
    }
  },
  mounted() {
    // 设置表格高度
    const t = this
    t.maxHeight = window.innerHeight - 240
    this.findBusinessVoList()
    this.findUserList()
  },
created() {

  },
methods: {
      findBusinessVoList() {
            let data = 'pageSize=' + this.pageSize + '&pageIndex=' + this.pageIndex + '&tag=1'
            data += '&startTime=' + this.startTime
            if (this.endTime === 0) {
                data += '&endTime=' + new Date().getTime()
            }
            if (this.carNo !== '') {
                data += '&carNo=' + this.carNo
            }
            if (this.bdName !== '') {
                data += '&bdName=' + this.bdName
            }
            if (this.uid !== undefined && this.uid != '') {
                data += '&uid=' + this.uid
            }
            findBusinessVoList(data).then(res => {
                console.log(res.data)
                this.businessVoList = res.data.list
                this.total = res.data.count
            })
      },
            changePage(p) {
              this.pageIndex = p
              this.findBusinessVoList()
            },
            dateHandleChange(date) {
                if (date[0] === '') {
                    this.startTime = 0
                } else {
                     this.startTime = moment(date[0], 'YYYY/MM/DD').valueOf()
                }
                if (date[1] === '') {
                    this.endTime = new Date().getTime()
                } else {
                    this.endTime = moment(date[1], 'YYYY/MM/DD').valueOf()
                }
            },
            formatDate(date) {
                return moment(date).format('YYYY-MM-DD')
            },
            update(row) {
                this.confirmTime = moment(row.row.modifyTime).format('YYYY-MM-DD hh:mm:ss')
                this.modifyTime = row.row.modifyTime
                this.upBid = row.row.bid
                this.vModel = true
            },
            vOk() {
                   let data = { bid: this.upBid, createTime: this.modifyTime }
                   updateCreateTime(data).then(() => {
                        this.findBusinessVoList()
                        this.$Message.success('操作成功')
                   })
            },
            vCancel(row) {
              console.log(row)
            },
            changePageNum(size) {
              this.pageSize = size
               this.findBusinessVoList()
            },
            findUserList() {
              let params = '&pageIndex=1&pageSize=10000'
              findUserList(params).then(res => {
                  this.userList = res.data.list
              })
            },

  },
}
</script>

<style scoped>
.add{
    position: absolute;
    right: 40px;
}
.search-bt{
      position: absolute;
    right: 140px;
}
.query-c{
  height: 50px;
}
</style>